<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        ref="approvalModal"
        id="approvalModal"
        size="lg"
        :title="'Approuver la requête ' + this.$props.modelRef"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-md-4">
              <label for="formrow-inputCity">Date début d'intervnetion *</label>
              <input
                type="date"
                v-model="aprovalForm.dateStart"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.aprovalForm.dateStart.$error,}" 
              />
            </div>
            <div class="col-md-4">
              <label for="formrow-inputCity">Date fin d'intervnetion *</label>
              <input
                type="date"
                v-model="aprovalForm.dateEnd"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.aprovalForm.dateEnd.$error,}" 
              />
            </div>
            <div class="col-md-4">
              <label for="formrow-inputCity">Heure fin d'intervnetion *</label>
              <input
                type="time"
                v-model="aprovalForm.timeEnd"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.aprovalForm.timeEnd.$error,}" 
              />
            </div>
          </div> <br/>
          <div class="row">
            <div class="col-xs-12 col-md col-lg">
              <label for="formrow-inputCity">Responsable d'exécution *</label>
              <multiselect v-model="aprovalForm.responsibleType" :searchable="true" track-by="id" label="title" :options="executionResponsibleTypes" placeholder="Select responsible type" :allow-empty="false">
                <template slot="singleexecutionResponsibleType" slot-scope="{ executionResponsibleType }">{{ executionResponsibleType.title }}</template>
              </multiselect>
            </div>
            <div class="col-xs-12 col-md col-lg" v-if="aprovalForm.responsibleType.id == 'supplier'">
              <label for="formrow-email-input">Prestataire *</label>
                <multiselect
                  @close="fetchEmployeesList()"
                  v-model="supplierID"
                  :searchable="true"
                  track-by="id"
                  label="name"
                  :options="suppliers"
                  :select-label="''"
                  placeholder="Select Supplier"
                  :allow-empty="false"
                >
                  <template
                    slot="singleSupplier"
                    slot-scope="{ supplier }"
                    >{{ supplier.name }}</template
                  >
                </multiselect>
            </div>
            <div class="col-xs-12 col-md-4 col-lg"  v-if="aprovalForm.responsibleType.id == 'supplier'">
              <div class="form-group">
                <label for="formrow-email-input">Employees *</label>
                <multiselect
                  v-model="aprovalForm.responsible"
                  :searchable="true"
                  track-by="id"
                  label="first_name"
                  :select-label="''"
                  :multiple="false"
                  :options="employeesList"
                  :allow-empty="false"
                  :class="{'is-invalid': submitted && $v.aprovalForm.responsible.$error,}"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{option.first_name}}
                  </template>
                </multiselect>
                <div
                  v-if="submitted && $v.aprovalForm.responsible.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.aprovalForm.responsible.required"
                    >Le champ est obligatoire.</span
                  >
                </div>
              </div>
            </div>
          </div> <br>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires *</label>
                <textarea
                  v-model="aprovalForm.comments"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary float-right" type="submit">
              Valider
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {fetchSuppliersListApi} from "@/api/common";
import { required , requiredIf ,maxLength} from "vuelidate/lib/validators";
export default {
  props: {
    modelUid: {
      type: Number,
    },
    modelRef: {
      type: String,
    },
    maintenanceContract : null,
  },
  components: {Multiselect},
  data() {
    return {
      show: false,
      suppliers: [],
      employeesList : [],
      supplierID : [],
      aprovalForm: {
        requestUid: "",
        dateStart: "",
        dateEnd: "",
        timeEnd: "",
        responsibleType: "",
        responsible: "",
        comments: "",
      },
      maintenance_contract : "",
      executionResponsibleTypes: [
        {
          id: 'company',
          title: 'SONATRACH',
        },

        {
          id: 'supplier',
          title: 'Prestataire'
        }
      ],
      submitted: false,
    };
  },
  
  validations: {
    aprovalForm: {
      dateStart      : { required },
      dateEnd        : { required },
      timeEnd        : { required,},
      responsibleType: { required },
      responsible    : {  required: requiredIf(function() {
            return this.aprovalForm.responsibleType == "supplier"
          }),  },
      comments       : { required },
    },
  },
  created() {
    this.fetchSuppliersList();
  },
  methods: {
    fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.suppliers))
        .catch(() => {})
        .finally(() => {});
    },
    fetchEmployeesList(){
      this.$http
        .post("/ops/requests/maintenance/usersList",this.supplierID)
        .then((res) => {
          this.aprovalForm.responsible = "";
          this.employeesList = [];
          this.employeesList = res.data.original.data })
        .catch(() => {})
        .finally(() => {});
    },
    submit() {
      this.maintenance_contract = this.$props.maintenanceContract;
      if(this.maintenance_contract == null && this.aprovalForm.responsibleType.id == "supplier"){
        this.$toast.warning("Vous ne pouvez pas effectuer cette opération , Car Cette base de vie ne contient aucun contrat d'entretien");
      }else{
        this.submitted = true
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.submitted = false
          this.aprovalForm.requestUid = this.$props.modelUid;

          var _this = this;
          this.$http
          .post("/ops/requests/maintenance/approve", this.aprovalForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$refs['approvalModal'].hide()
                this.$emit('realoadData',true)
                _this.$toast.success(res.data.original.msg);
                this.aprovalForm.requestUid = "";
                this.aprovalForm.dateStart = "";
                this.aprovalForm.dateEnd = "";
                this.aprovalForm.timeEnd = "";
                this.aprovalForm.responsibleType = "";
                this.aprovalForm.responsible = "";
                this.aprovalForm.comments = "";
                break;

              case 500:
                _this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            _this.$toast.warning(error.message);
          })
          .finally(() => {});
        }
      }
    },
  },
};
</script>
